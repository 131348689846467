import React, { Suspense, useEffect } from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import { connect } from "react-redux";

import "./App.css";
import Layout from "./hoc/Layout/Layout";
import Spinner from "./components/UI/Spinner/Spinner";
import * as routez from "./shared/routes";
import * as actions from "./store/actions/index";
import axios from "./axios-learnone-admin";
import withErrorHandler from "../src/hoc/withErrorHandler/withErrorHandler";

const Auth = React.lazy(() => {
  return import("./containers/Auth/Auth");
});

const Users = React.lazy(() => {
  return import("./containers/Users/Users");
});

const UsersDetail = React.lazy(() => {
  return import("./containers/UsersDetail/UsersDetail");
});

const PendingPurchases = React.lazy(() => {
  return import("./containers/Purchase/PendingPurchases");
});

const ToBeDispatched = React.lazy(() => {
  return import("./containers/Purchase/ToBeDispatched");
});

const ToBeDelivered = React.lazy(() => {
  return import("./containers/Purchase/ToBeDelivered");
});

const LessonsPage = React.lazy(() => {
  return import("./containers/Lessons/Lessons");
});

const Delivered = React.lazy(() => {
  return import("./containers/Purchase/Delivered");
});

const Rejected = React.lazy(() => {
  return import("./containers/Purchase/RejectedPurchases");
});

const Topics = React.lazy(() => {
  return import("./containers/Topics/Topics");
});

const Subjects = React.lazy(() => {
  return import("./containers/Subjects/Subjects");
});

const SubjectQuotes = React.lazy(() => {
  return import("./containers/SubjectQuotes/SubjectQuotes");
});

const FreshUsers = React.lazy(() => {
  return import("./containers/Users/FreshUsers");
});

const Lectures = React.lazy(() => {
  return import("./containers/Lecturers/Lecture");
});

const MoreOptions = React.lazy(() => {
  return import("./containers/MoreOptions");
});

function App(props) {
  const onTryAutoSignIn = props.onTryAutoSignIn;

  useEffect(() => {
    onTryAutoSignIn();
  }, [onTryAutoSignIn]);

  let routes = (
    <Suspense fallback={<Spinner />}>
      <Switch>
        <Route path={routez.AUTH} component={Auth} />
      </Switch>
    </Suspense>
  );

  if (props.isAuthenticated) {
    routes = (
      <Suspense fallback={<Spinner />}>
        <Switch>
          <Route exact path={routez.HOME}>
            <Redirect to={routez.PENDING_PURCHASE} />
          </Route>
          <Route exact path={routez.USERS_DETAIL} component={UsersDetail} />
          <Route exact path={routez.USERS} component={Users} />
          <Route exact path={routez.FRESHUSERS} component={FreshUsers} />
          <Route exact path={routez.LECTURERS} component={Lectures} />
          <Route
            exact
            path={routez.PENDING_PURCHASE}
            component={PendingPurchases}
          />
          <Route
            exact
            path={routez.TO_BE_DISPATCHED}
            component={ToBeDispatched}
          />
          <Route
            exact
            path={routez.TO_BE_DELIVERED}
            component={ToBeDelivered}
          />
          <Route exact path={routez.LESSONS} component={LessonsPage} />
          <Route exact path={routez.DELIVERED} component={Delivered} />
          <Route exact path={routez.REJECTED} component={Rejected} />
          <Route exact path={routez.TOPICS} component={Topics} />
          <Route exact path={routez.SUBJECTS} component={Subjects} />
          <Route exact path={routez.QUOTES} component={SubjectQuotes} />
          <Route exact path={routez.MORE_OPTIONS} component={MoreOptions} />
          <Redirect path="/" to={routez.HOME} />
        </Switch>
      </Suspense>
    );
  }

  return (
    <div className="App">
      <Layout>{routes}</Layout>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    isAuthenticated: state.auth.token !== null,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onTryAutoSignIn: () => dispatch(actions.authCheckState()),
  };
};

const withErrorhandlerWrappedComponent = withErrorHandler(App, axios);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withErrorhandlerWrappedComponent);
