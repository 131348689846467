export const AUTH = '/';
export const HOME = '/';
export const USERS = '/users';
export const USERS_DETAIL = '/users/:id';
export const APPROVED_PURCHASE_LIST = '/approved-purchase-list'
export const PENDING_PURCHASE = '/purchases/pending'
export const TO_BE_DISPATCHED = '/purchases/to-be-dispatched'
export const TO_BE_DELIVERED = '/purchases/to-be-delivered'
export const LESSONS = '/lesson'
export const DELIVERED = '/purchases/delivered'
export const REJECTED = '/purchases/rejected'
export const TOPICS = "/topics";
export const SUBJECTS = "/subjects";
export const QUOTES = "/quotes";
export const MORE_OPTIONS = "/more-options";
export const FRESHUSERS = "/fresh-users";
export const LECTURERS = "/lectures"
export const SETTINGS = "/settings"
