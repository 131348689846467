import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core';
import AppBar from '@material-ui/core/AppBar';

const useStyles = makeStyles((theme) => ({
    appBarShift: props => ({
        width: `calc(100% - ${props.drawerWidth}px)`,
        marginLeft: props.drawerWidth,
        transition: theme.transitions.create(['margin', 'width'], {
          easing: theme.transitions.easing.easeOut,
          duration: theme.transitions.duration.enteringScreen,
        }),
      }),
}));

const FHAppBar = (props) => {
    const classes = useStyles(props);

    return (
        <AppBar
            position="fixed"
            className={clsx(classes.appBar, {
                [classes.appBarShift]: props.open,
            })}>
            {props.children}
        </AppBar>
    );
};

export default FHAppBar;