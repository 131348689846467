import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { createStore, compose, applyMiddleware, combineReducers } from 'redux';
import { Provider } from 'react-redux';
import thunk from 'redux-thunk';

import CssBaseline from "@material-ui/core/CssBaseline";
import { responsiveFontSizes, ThemeProvider } from "@material-ui/core/styles";

import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';

//custom theme
import muiTheme from "./theme/theme";

import authReducer from './store/reducers/auth';
import alertReducer from './store/reducers/alert';

const composeEnhancers = (process.env.NODE_ENV === 'development' ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ : null) || compose;

const rootReducer = combineReducers(
  {
    auth: authReducer,
    alert: alertReducer
  }
);
const store = createStore(rootReducer, composeEnhancers(applyMiddleware(thunk)));

const AppWrapper = () => (
  <Provider store={store}>
    <BrowserRouter>
      <React.StrictMode>
        <App />
      </React.StrictMode>
    </BrowserRouter>
  </Provider>
);

const theme = responsiveFontSizes(muiTheme, {
    factor: 1.125,
});

const AppWithTheme = (
    <ThemeProvider theme={theme}>
        {/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
        <CssBaseline />
        <AppWrapper />
    </ThemeProvider>
);

ReactDOM.render(AppWithTheme, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
