import React from 'react'
import {
    Home, 
    Lock, 
    Category, 
    Fastfood, 
    Person, 
    LocalShipping, 
    Airplay,
    Cancel, 
    CheckCircleOutline, 
    FormatQuote,
    ChildCare,
    EmojiPeople,
    Settings
} from '@material-ui/icons';

export const FHLockIcon = 'lockIcon';
export const FHHomeIcon = 'homeIcon';
export const FHCategoryIcon = 'categoryIcon';
export const FHProductIcon = 'productIcon'
export const FHPerson = 'person'
export const FHLocalShipping = 'localShipping'
export const FHCheckCircleOutlineIcon = 'checkCircleOutlineIcon'
export const FHCancelIcon = 'cancelIcon'
export const FHAirplayIcon = 'airplayIcon'
export const FHFormatQuoteIcon = 'formatQuoteIcon'
export const FHChildCare = 'childcareIcon'
export const FHEmojiPeople = 'emojiPeopleIcon'
export const FHSETTINGS = 'emojiPeopleIcon'

export const getIcon = (iconName) => {
    switch (iconName) {
        case FHLockIcon:
            return <Lock/>;
        case FHHomeIcon:
            return <Home/>;
        case FHCategoryIcon:
            return <Category/>;
        case FHProductIcon:
            return <Fastfood/>;
        case FHPerson:
            return <Person/>;
        case FHLocalShipping:
            return <LocalShipping/>;
        case FHAirplayIcon:
            return <Airplay/>;
        case FHCheckCircleOutlineIcon:
            return <CheckCircleOutline/>;
        case FHCancelIcon:
            return <Cancel/>;
        case FHFormatQuoteIcon:
            return <FormatQuote/>;
        case FHChildCare:
            return <ChildCare/>;
        case FHEmojiPeople:
            return <EmojiPeople/>;
        case FHSETTINGS:
            return <Settings />
        default:
            throw Error('Icon not found');
    }
};