import React, { useCallback } from 'react';
import clsx from 'clsx';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Divider from '@material-ui/core/Divider';

import DrawerHeader from '../../components/Navigation/FHDrawerHeader/FHDrawerHeader'
import DrawerItems from '../../components/Navigation/FHDrawerItems/FHDrawerItems'
import SideDrawer from '../../components/Navigation/FHSideDrawer/FHSideDrawer';
import AppBar from '../../components/Navigation/FHAppBar/FHAppBar';
import ToolBar from '../../components/Navigation/FHToolBar/FHToolBar';
import Alert from '../../components/UI/FHAlert/FHAlert';

import * as icons from '../../helpers/icons'
import { authLogout, removeAlert } from '../../store/actions/index';
import * as routz from '../../shared/routes'

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
    },
    content: {
        marginTop: '56px',
        flexGrow: 1,
        padding: theme.spacing(3),
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        marginLeft: -drawerWidth,
    },
    contentShift: {
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
        marginLeft: 0,
    }
}));

const noAuthDrawerItems = [
    {
        key: 'signIn',
        route: routz.AUTH,
        icon: icons.FHLockIcon,
        label: 'Sign In'
    }
]

const authDrawerItems = [
  {
    key: "subjects",
    route: routz.SUBJECTS,
    icon: icons.FHCategoryIcon,
    label: "Subjects",
  },
  {
    key: "topics",
    route: routz.TOPICS,
    icon: icons.FHProductIcon,
    label: "Topics",
  },
  {
    key: "lessons",
    route: routz.LESSONS,
    icon: icons.FHAirplayIcon,
    label: "Lessons",
  },
  {
    key: "lecturers",
    route: routz.LECTURERS,
    icon: icons.FHEmojiPeople,
    label: "Lecturers",
  },
  {
    key: "quotes",
    route: routz.QUOTES,
    icon: icons.FHFormatQuoteIcon,
    label: "Subject Quotes",
  },
  {
    key: null,
    component: <Divider />
  },
  {
    key: "users",
    route: `${routz.USERS}?enabled=true`,
    icon: icons.FHPerson,
    label: "Users",
  },
  {
    key: "blocked-users",
    route: `${routz.USERS}?enabled=false`,
    icon: icons.FHPerson,
    label: "Blocked Users",
  },
  {
    key: "fresh-users",
    route: `${routz.FRESHUSERS}`,
    icon: icons.FHChildCare,
    label: "Fresh Users",
  },
  {
    key: null,
    component: <Divider />
  },
  {
    key: "await_confirmation_purchase_list",
    route: routz.PENDING_PURCHASE,
    icon: icons.FHCategoryIcon,
    label: "Pending Purchases",
  },
  {
    key: "to_be_dispatched",
    route: routz.TO_BE_DISPATCHED,
    icon: icons.FHProductIcon,
    label: "To Be Dispatched",
  },
  {
    key: "to_be_delivered",
    route: routz.TO_BE_DELIVERED,
    icon: icons.FHLocalShipping,
    label: "To Be Delivered",
  },
  {
    key: "delivered",
    route: routz.DELIVERED,
    icon: icons.FHCheckCircleOutlineIcon,
    label: "Delivered Purchases",
  },
  {
    key: "rejected",
    route: routz.REJECTED,
    icon: icons.FHCancelIcon,
    label: "Rejected Purchases",
  },
  {
    key: null,
    component: <Divider />
  },
  {
    key: "moreOptions",
    route: routz.MORE_OPTIONS,
    icon: icons.FHAirplayIcon,
    label: "More Options",
  },
  // {
  //   key: "settings",
  //   route: routz.SETTINGS,
  //   icon: icons.FHSETTINGS,
  //   label: "Settings",
  // },
];

const Layout = (props) => {
    const classes = useStyles();
    const drawerItems = props.isAuthenticated ? authDrawerItems : noAuthDrawerItems;

    const [open, setOpen] = React.useState(false);

    const handleDrawerOpen = useCallback(() => {
        setOpen(true);
    }, []);

    const handleDrawerClose = useCallback(() => {
        setOpen(false);
    }, []);

    const removeAlert = props.removeAlert;
    const handleAlertClose = useCallback((alertId) => {
        removeAlert(alertId);
    }, [removeAlert]);

    return (
        <div className={classes.root}>
            <CssBaseline/>
            <AppBar open={open} drawerWidth={drawerWidth}>
                <ToolBar
                    isAuthenticated={props.isAuthenticated}
                    open={open}
                    onClickHandler={props.logoutHandler}
                    handleDrawerOpen={handleDrawerOpen}/>
            </AppBar>
            <SideDrawer open={open} drawerWidth={drawerWidth}>
                <DrawerHeader handleDrawerClose={handleDrawerClose}/>
                <Divider/>
                <DrawerItems items={drawerItems}/>
            </SideDrawer>
            <main
                className={clsx(classes.content, {
                    [classes.contentShift]: open,
                })}>
                <Alert handleAlertClose={handleAlertClose} alerts={props.alerts}/>
                {props.children}
            </main>
        </div>
    );
}

const mapStateToProps = (state) => {
    return {
        isAuthenticated: state.auth.token !== null,
        alerts: state.alert.alerts
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        logoutHandler: () => dispatch(authLogout()),
        removeAlert: (alertId) => dispatch(removeAlert(alertId))
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(Layout);
