import React from 'react';
import { makeStyles } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';

const useStyles = makeStyles((theme) => ({
    drawerHeader: {
        display: 'flex',
        alignItems: 'center',
        padding: theme.spacing(0, 1),
        // necessary for content to be below app bar
        ...theme.mixins.toolbar,
        justifyContent: 'flex-end',
    },
}));

const FHDrawerHeader = (props) => {
    const classes = useStyles();

    return (
        <div className={classes.drawerHeader}>
            <IconButton onClick={props.handleDrawerClose}>
                <ChevronLeftIcon />
            </IconButton>
        </div>
    );
};

export default FHDrawerHeader;