// General Const
const selleroneAPIHostProd = 'https://api.mobileclass.net/';
const selleroneAPIHostDev = 'http://localhost:8082';
export const selleroneAPIHost = process.env.NODE_ENV === 'development' ? selleroneAPIHostDev : selleroneAPIHostProd;

// Auth Const
export const authRedirectPath = '/';
export const authRequestTimeoutSec = 3600;

// Firebase API Keys and other configurations
export const firebaseConfig = {
	apiKey: "AIzaSyANSVxVKjeWZNYbtA69vsxj5n9Mb54qdEg",
	authDomain: "learnone-ui-prod.firebaseapp.com",
	projectId: "learnone-ui-prod",
	storageBucket: "learnone-ui-prod.appspot.com",
	messagingSenderId: "7253755044",
	appId: "1:7253755044:web:1db1446bfbfccda4c15231",
	measurementId: "G-07SV585TBM",
};

export const fileUploadDirectory =
	process.env.NODE_ENV === "development" ? "subject_images_dev " : "subject_images";
    
// SellerOne Api Routes
export const loginRoute = '/v1/auth/login';
export const usersAdminRoute = '/v1/admin/users'