import React from 'react';
import clsx from 'clsx';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import { makeStyles } from '@material-ui/core';
import FHButton from '../../UI/FHButton/FHButton';

const useStyles = makeStyles((theme) => ({
    menuButton: {
        marginRight: theme.spacing(2),
    },
    hide: {
        display: 'none',
    },
    toolbarTitle: {
        display: 'inline-flex'
    },
    fhToolbar: {
        justifyContent: 'space-between'
    }
}));

const FHToolBar = (props) => {
    const classes = useStyles(props);

    const logoutBtn = props.isAuthenticated ?  <FHButton onClick={props.onClickHandler} color="inherit" variant="text">Logout</FHButton> : null;

    return (
        <Toolbar className={classes.fhToolbar}>
            <div>
                <IconButton
                    color="inherit"
                    aria-label="open drawer"
                    onClick={props.handleDrawerOpen}
                    edge="start"
                    className={clsx(classes.menuButton, props.open && classes.hide)}>
                    <MenuIcon />
                </IconButton>
                <Typography className={classes.toolbarTitle} variant="h6" noWrap>
                    Mobile Class Admin
                </Typography>
            </div>
            {logoutBtn}
        </Toolbar>
    );
};

export default FHToolBar;