import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';

const useStyles = makeStyles((theme) => ({
  drawer: props => ({
    width: props.drawerWidth,
    flexShrink: 0,
  }),
  drawerPaper: props => ({
    width: props.drawerWidth,
  }),
}));

const FHSideDrawer = (props) => {
  const classes = useStyles(props);

  return (
    <Drawer
      className={classes.drawer}
      variant="persistent"
      anchor="left"
      open={props.open}
      classes={{
        paper: classes.drawerPaper,
      }}>
      {props.children}
    </Drawer>
  );
}

export default FHSideDrawer;