import React from 'react';
import { Link } from "react-router-dom";
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';

import { getIcon } from '../../../helpers/icons'


const FHDrawerItems = (props) => {
    const items = (
        <React.Fragment>
            {props.items.map((item, index) => {
                return item.key !== null ? (
                    <ListItem button key={index} component={Link} to={item.route}>
                        <ListItemIcon>{getIcon(item.icon)}</ListItemIcon>
                        <ListItemText primary={item.label} />
                    </ListItem>
                ) : item.component ;
            })}
        </React.Fragment>
    )

    return (
        <List>
            {items}
        </List>
    );
};

export default FHDrawerItems;
